import { createTheme } from "@mui/material/styles";

// A custom theme for BOB
const theme = createTheme({
  palette: {
    mode: "light",
    common: {
      black: "#000",
      white: "#fff",
    },
    text: {
      primary: "#F9F6F4",
      secondary: "#263788",
    },
    primary: {
      main: "#203190",
      light: "#515f9f",
      dark: "#1a265f",
    },
    secondary: {
      main: "#FFD3CC",
      light: "#ffdbd6",
      dark: "#b2938e",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    background: {
      paper: "#F9F6F4",
      default: "#203190",
    },
    action: {
      active: "#ffffff",
    },
    divider: "#263788",
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 852,
      md: 1090,
      lg: 1411,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['"Karla", sans-serif'],
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontSize: 16,
    fontWeightMedium: 500,
    h1: {
      fontFamily: '"Juana-ExtraLight", sans-serif',
      fontSize: "30.88px",
      textTransform: "inherit",
      letterSpacing: "0.25px",
      lineHeight: "41.07px",
      "@media only screen and (min-width:768px)": {
        fontSize: "57px",
        lineHeight: "67.66px",
        letterSpacing: "0.34px",
      },
      "@media only screen and (min-width:1280px)": {
        fontSize: "67px",
        lineHeight: "87.66px",
        letterSpacing: "0.54px",
      },
    },
    h2: {
      fontSize: "28px",
      fontFamily: '"Juana-ExtraLight", sans-serif',
      letterSpacing: "0.23px",
      lineHeight: "39.66px",
      "@media only screen and (min-width:768px)": {
        fontSize: "47px",
        letterSpacing: "0.38px",
        lineHeight: "64.66px",
      },
    },
    h3: {
      fontSize: "28px",
      fontFamily: '"Juana-ExtraLight", sans-serif',
      letterSpacing: "0.23px",
      lineHeight: "39.66px",
      "@media only screen and (min-width:768px)": {
        fontSize: "43px",
        letterSpacing: "0.35px",
        lineHeight: "54px",
      },
    },
    h4: {
      fontSize: "32px",
      fontFamily: '"Juana-ExtraLight", sans-serif',
      letterSpacing: "0.26px",
      lineHeight: "39px",
      "@media only screen and (min-width:768px)": {
        fontSize: "37px",
        letterSpacing: "0.35px",
        lineHeight: "54px",
      },
    },
    h5: {
      fontSize: "27px",
      letterSpacing: "0.22px",
      lineHeight: "33px",
      fontFamily: '"Juana-ExtraLight", sans-serif',
      "@media only screen and (min-width:768px)": {
        fontSize: "32px",
        letterSpacing: "0.26px",
        lineHeight: "39px",
      },
    },
    h6: {
      fontSize: "12px",
      fontFamily: '"Karla", sans-serif',
      letterSpacing: "0.1px",
      lineHeight: "39px",
    },
    subtitle1: {
      fontFamily: '"Karla", sans-serif',
      fontSize: "10px",
      fontWeight: "bold",
      letterSpacing: "3.33px",
      lineHeight: "39.24px",
      "@media only screen and (min-width:768px)": {
        fontSize: "15px",
        letterSpacing: "5px",
      },
    },
    subtitle2: {
      fontFamily: '"Karla", sans-serif',
      fontSize: "14px",
      opacity: "0.55",
      letterSpacing: "0",
      LineHeight: "39.24px",
      "@media only screen and (min-width:768px)": {
        fontSize: "18px",
      },
    },
    body1: {
      fontFamily: '"Karla", sans-serif',
      fontSize: "17px",
      letterSpacing: 0,
      lineHeight: "27.24px",
      "@media only screen and (min-width:768px)": {
        lineHeight: "29.24px",
      },
    },
    body2: {
      fontFamily: '"Karla", sans-serif',
      fontSize: "16px",
      letterSpacing: 0,
      lineHeight: "27.24px",
      "@media only screen and (min-width:768px)": {
        lineHeight: "29.24px",
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 115,
      },
      "@media (min-width:600px):": {
        minHeight: 64,
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "23px",
          paddingRight: "23px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderTop: "1px dashed #263788",
          backgroundColor: "transparent",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        divider: {
          borderBottom: "1px dashed #263788",
          paddingTop: 20,
          paddingBottom: 20,
        },
      },
    },
  },
  themeName: "Bob Theme",
});

export default theme;
