module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["fi","en"],"defaultLanguage":"fi","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BOB Hair Studio ","short_name":"BOB","description":"Number one hair place in town.","lang":"en","start_url":"/","background_color":"#fff","theme_color":"#203190","display":"minimal-ui","icon":"src/images/bob.png","crossOrigin":"use-credentials","icon_options":{"purpose":"any maskable"},"localize":[{"start_url":"/fi/","lang":"fi","name":"BOB Hair Studio ","short_name":"BOB","description":" Ykköspaikka kaupungissa."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"4f6dc0a20e46b83252937b0254d36a87"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-XXZM157GLN","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
